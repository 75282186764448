












import { Component, Vue } from "vue-property-decorator";
import TopBar from "./TopBar.vue";
import BottomBar from "./BottomBar.vue";

@Component({
  components: {
    TopBar,
    BottomBar
  },
  metaInfo: {
    title: "Welcome!"
  }
})
export default class EnHomeLayout extends Vue {
  get isHomePage() {
    return this.$route.name == "en-home";
  }
}
