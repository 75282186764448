





































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class BottomBar extends Vue {
  navDrawer = null;

  socialLinks = [
    {
      icon: "mdi-facebook",
      href: "http://fb.me/kahev2018"
    },
    {
      icon: "mdi-twitter",
      href: "https://twitter.com/kahev2018"
    },
    {
      icon: "mdi-youtube",
      href: "https://www.youtube.com/c/kahev2018"
    },
    {
      icon: "mdi-instagram",
      href: "https://www.instagram.com/kahev2018"
    }
  ];

  menuItems = [
    {
      title: "Foundation Certificate",
      to: {
        name: "vakif-senedi"
      }
    },
    {
      title: "Activity Reports",
      to: {
        name: "faaliyet-raporlari"
      }
    },
    {
      title: "Donor Rights",
      to: {
        name: "bagisci-haklari"
      }
    },
    {
      title: "Terms of Use",
      to: {
        name: "kullanim-sartlari"
      }
    },
    {
      title: "Persona Data Protection",
      to: {
        name: "kvkk"
      }
    }
  ];
}
