
























































































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class NavBar extends Vue {
  logo = require("@/assets/logo-w.svg");

  navDrawer = false;

  socialLinks = [
    {
      icon: "mdi-twitter",
      href: "https://twitter.com/kahev2018"
    },
    {
      icon: "mdi-facebook",
      href: "http://fb.me/kahev2018"
    },
    {
      icon: "mdi-youtube",
      href: "https://www.youtube.com/c/kahev2018"
    },
    {
      icon: "mdi-instagram",
      href: "https://www.instagram.com/kahev2018"
    }
  ];

  menuItems = [
    {
      title: "HOME",
      to: {
        name: "en-home"
      }
    },
    {
      title: "KAHEV NEWS",
      to: {
        name: "en-news"
      }
    }
  ];

  apps = [
    {
      title: "ONLINE OPERATIONS",
      icon: "mdi-login-variant",
      href: "https://fonzip.com/kahev"
    },
    {
      title: "ACADEMY",
      icon: "mdi-school",
      href: "https://kahevakademi.com"
    },
    {
      title: "STORE",
      icon: "mdi-store",
      href: "https://www.kahevdukkan.com"
    },
    {
      title: "MEMBERSHIP",
      icon: "mdi-cursor-default-click",
      href: "https://fonzip.com/kahev/form/uyelik"
    },
    {
      title: "TR",
      icon: "mdi-translate",
      href: "/"
    }
  ];

  dialog = false;
}
